<template>
  <section class="content">
    <div class="flex justify-content-center">
      <div class="w-100 card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4">
              <div class="info-box">
                <span class="info-box-icon bg-info elevation-1"
                  ><i class="fas fa-bookmark"></i
                ></span>
                <div class="info-box-content">
                  <h5 class="info-box-text">TOTAL TICKETS</h5>
                  <h5 class="info-box-number">{{ allTicket.total }}</h5>
                </div>
                <!-- /.info-box-content -->
              </div>
              <!-- /.info-box -->
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <div class="info-box">
                <span class="info-box-icon bg-warning elevation-1"
                  ><i class="fas fa-inbox"></i
                ></span>
                <div class="info-box-content">
                  <h5 class="info-box-text">PENDING TICKETS</h5>
                  <h5 class="info-box-number">{{ allTicket.pending }}</h5>
                </div>
                <!-- /.info-box-content -->
              </div>
              <!-- /.info-box -->
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <div class="info-box">
                <span class="info-box-icon bg-success elevation-1"
                  ><i class="fas fa-check-double"></i
                ></span>
                <div class="info-box-content">
                  <h5 class="info-box-text">CLOSED TICKETS</h5>
                  <h5 class="info-box-number">{{ allTicket.closed }}</h5>
                </div>
                <!-- /.info-box-content -->
              </div>
              <!-- /.info-box -->
            </div>
          </div>
          <div class="row mb-2" style="row-gap: 0.5rem;">
            <div class="col-12">
              <h6 class="mb-0">Filter Pencarian</h6>
            </div>
            <div class="col-12 col-sm-auto">
              <select class="form-control" @change="dataType($event)">
                <option value="createdOn">Dibuat Antara</option>
                <option value="lastTm">Update Antara</option>
              </select>
            </div>
            <div class="col-12 col-sm-auto">
              <div class="input-group">
                <input type="text" ref="daterange" class="form-control" style="border-right: 0" />
                <div class="input-group-append">
                  <div class="input-group-text" style="background-color: #fff">
                    <span><span class="fa fa-calendar"></span></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <select class="form-control" @change="ticketType($event)">
                <option value="" disabled selected>Status</option>
                <option value="open">Open</option>
                <option value="closed">Closed</option>
                <option value="pending">Pending</option>
              </select>
            </div>
            <div class="col-auto ml-auto">
    					<div class="btn-group" ref="toolbar">
    						<button type="button" data-action="read" class="btn btn-default"><i
    								class="fa fa-sync-alt"></i></button>
    						<button type="button" :disabled="downloading" v-on:click="downloadXlsx"
    							data-action="export-xlsx" class="btn btn-default"><i class="fa fa-download"></i> Download</button>
    					</div>
    				</div>
          </div>
          <div class="table-ticket">
            <table class="table table-hover" ref="tblticket" id="tblticket">
              <thead>
                <tr>
                  <th>AWB</th>
                  <th>JUDUL</th>
                  <th>PIC</th>
                  <th>CS</th>
                  <th>PRIORITAS</th>
                  <th>STATUS</th>
                  <th>STATUS KIRIMAN</th>
                  <th>DIBUAT</th>
                  <th>UPDATE</th>
                  <th>UMUR</th>
                  <th>KOMENTAR</th>
                  <th>TINDAKAN</th>
                </tr>
              </thead>
              <tbody @click="handleClick"></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import 'jquery-ui';
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import moment from "moment";

export default {
  name: "Ticket",
  data() {
    return {
        dateStart: moment().add(-3, 'days').format("YYYY-MM-DD"),
        dateEnd: moment().format("YYYY-MM-DD"),
        method: "",
        roles: "",
        allTicket: {},
        downloading: false,
        show: false,
        filter: {},
        form: {
        status: "",
        },
        dt1: moment().add(-3, 'days'),
        dt2: moment(),
        data_type: "createdOn",
        ticket_type: "",
    };
  },
  watch:{
    $route (to, from){
      if(to.path === "/settings/tickets/all")
      {
        // sessionStorage.filterData = '';
		    // sessionStorage.filterStatus = '';
        // this.filter = {};
        sessionStorage.clear();
        this.table.api().ajax.reload();
        this.loadAllStatus();
      }
    }
  },
  created: function () {
    // console.log("filter: ",this.$route.params);
    this.roles = this.$route.meta.roles;
    this.userInfo = auth.user();
    if(this.$route.params.filter) {
      this.filter = this.$route.params.filter;
      this.loadAllStatus(this.filter);
    } else {
        this.filter.dt1 = this.dt1.format('YYYY-MM-DD');
        this.filter.dt2 = this.dt2.format('YYYY-MM-DD');
        this.loadAllStatus(this.filter);
    }

    if(this.filter.dt1 && this.filter.dt2)
    {
      this.dt1 = moment(this.filter.dt1);
      this.dt2 = moment(this.filter.dt2);
    } else {
        this.filter.dt1 = this.dt1.format('YYYY-MM-DD');
        this.filter.dt2 = this.dt2.format('YYYY-MM-DD');
    }
    this.dateStart = this.dt1.format('YYYY-MM-DD');
    this.dateEnd = this.dt2.format('YYYY-MM-DD');
    if(this.filter.gid)
    {
      this.groupId = this.filter.gid;
      this.dateStart = '';
      this.dateEnd = '';
	  }
    if(Object.keys(this.filter).length<1)
    {
      if(sessionStorage.filterData)this.filter = JSON.parse(sessionStorage.filterData);
    }
    else{
      sessionStorage.filterData = JSON.stringify(this.filter);
      sessionStorage.filterStatus = 1;
    }
    this.filter.datatype = this.data_type;

    if (this.filter.open > 0) {
        this.filter.ticket = 'open';
    } else if (this.filter.closed > 0) {
        this.filter.ticket = 'closed';
    } else {
        this.ticket_type = '';
    }
    
    // this.filter.ticket = this.filter.open > 0 ? this.ticket_type == "open" : this.ticket_type == "closed";
  },
  methods: {
    onDate() {
			this.table.api().ajax.reload();
		},
    dataType(event) {
			this.data_type = event.target.value;
      this.filter.datatype = this.data_type;
			// this.table.api().ajax.reload();
		},
    ticketType(event) {
      sessionStorage.clear();
      let self = this;
      self.filter = {};
			self.ticket_type = event.target.value;
      self.filter.ticket = self.ticket_type;
      self.filter.dt1 = self.dateStart;
      self.filter.dt2 = self.dateEnd;
      self.filter.datatype = self.data_type;
      // console.log(self.filter.ticket);
      self.onDate();
      self.loadAllStatus(self.filter.ticket);
			// self.table.api().ajax.reload();
		},
    loadAllStatus(params) {
      // console.log("params ", Object.keys(params));
      var data = '';
      if (!params) {
        data = '';
      } else if(params == 'open' || params == 'closed' || params == 'pending'){
        var ticket = { ticket: params, dt1: this.dateStart, dt2: this.dateEnd };
        data = new URLSearchParams(ticket);
      }else {
        data = Object.keys(params)
          .map(
            (key) =>
              encodeURIComponent(key) + "=" + ((params[key] ===false || params[key] === undefined)?0 : encodeURIComponent(params[key]))
          )
          .join("&");
      }
      // console.log(data);

      let url = '';
      // let url = '';
      if (params) {
        url = "/ticket/history/all_tickets?" + data;
      } else {
        url = "/ticket/history/all_tickets";
      }

        authFetch(url)
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.allTicket = js.data;
        });
    },
    downloadXlsx: function (e) {
			this.downloading = true;
			var filter = JSON.parse(JSON.stringify(this.filter));
			// console.log(filter)
			var data = Object.keys(filter)
				.map(
					(key) =>
					encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
				)
				.join("&");
			// console.log(data)
			authFetch("/ticket/history/download", {
					method: "POST",
					body: data,
				})
				.then((response) => response.blob())
				.then((blob) => {
					setTimeout(() => {
						this.downloading = false;
					}, 1000);
					var url = window.URL.createObjectURL(blob);
					var a = document.createElement("a");
					a.href = url;
					a.download = "data.xlsx";
					document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
					a.click();
					a.remove(); //afterwards we remove the element again
				});
		},
    infoClaim(claim){
      var statusClaim = '';
      if (claim == 1)
        statusClaim = '<span class="badge badge-danger">Paket Diclaim</span>';
      else if (claim == 2)
        statusClaim = '<span class="badge badge-success">Claim disetujui</span>';
      else if (claim == 3)
        statusClaim = '<span class="badge badge-danger">Claim ditolak</span>';
      return statusClaim;
    },
    infoStatus(track) 
    {
      var output = '';
      if (track.cancel == 1)
        output += '<span class="badge badge-danger">Cancel</span>';
      else if (track.pickupStatus == 0)
        output += '<span class="badge badge-inverse">DRAFT</span>';
      else
      {
        if (track.trackingStatus == 0)
        {
          if(parseInt(track.order_type) == 1){
            output += '<span class="badge badge-secondary">Menunggu Drop</span>';
          } else {
            output += '<span class="badge badge-secondary">Menunggu Pickup</span>';
          }
          output += this.infoClaim(track.retClaim);
        }
        else if (track.trackingStatus == 1)
        {
          output += '<span class="badge badge-warning">Proses</span>';
          output += this.infoClaim(track.retClaim);
        }
        else if (track.trackingStatus == 2)
        {
          output += '<span class="badge badge-success">Terkirim</span>';
          output += this.infoClaim(track.retClaim);
        }
        else if (track.trackingStatus == 3)
        {
          output += '<span class="badge badge-warning">Proses Retur</span>';
          output += this.infoClaim(track.retClaim);
        }
        else if (track.trackingStatus == 4) {
          if (track.retStatus == 1) 
          {
            output += '<span class="badge badge-danger">Retur Diterima</span>';
            output += this.infoClaim(track.retClaim);
          }
          else if (!track.retStatus)
          {
            output += '<span class="badge badge-info">Retur Diserahkan</span>';
            output += this.infoClaim(track.retClaim);
          }
        }
        else if (track.trackingStatus == 5) 
        {
          output += '<span class="badge badge-danger">Bermasalah</span>';
          output += this.infoClaim(track.retClaim);
        }
      }
      // var output = '';
      // output += this.statusTracking;
      // output += this.statusClaim;
      return output;
    },
    handleClick(e) {
      if (e.target.matches(".link-order")) {
          let route = this.$router.resolve({
                path: "/order/detail/" + e.target.dataset.id
            });
            window.open(route.href,"_blank");
        // return false;
      }
      if(e.target.closest('.btn-comment')) {
        //this.isShowTicket = !this.isShowTicket;
        var el = e.target.closest('.btn-comment');
        // console.log(el)
        this.$root.$emit("openTicketComment", el.dataset.id);
        return false;
      }
      if (e.target.matches("button")) {
        if (e.target.dataset.action == "change") {
          this.form.status = e.target.dataset.status;
          Swal.fire({
            title:
              e.target.dataset.status == 3
                ? "Pending?"
                : e.target.dataset.status == 2
                ? "Closed?"
                : "Open?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              var data = Object.keys(this.form)
                .map(
                  (key) =>
                    encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(this.form[key])
                )
                .join("&");

              authFetch("/ticket/history/" + e.target.dataset.id, {
                method: "PUT",
                body: data,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  if (js.success) {
                    Swal.fire("Proses Berhasil", ``, "success");
                    this.table.api().ajax.reload();
                    this.loadAllStatus();
                  } else {
                    Swal.fire("Proses gagal", ``, "error");
                    this.table.api().ajax.reload();
                  }
                });
            }
          });
        }
        // console.log(e.target);
        return false;
      }
    },
  },
  mounted() {
		this.$gtag.event(`page_view`, {
			'event_name': 'List Tiket'
		});
    const e = this.$refs;
    const self = this;
    this.$root.$on('reloadDashboardTicket', () => {
        this.loadAllStatus();
        this.table.api().ajax.reload( null, false ); // user paging is not reset on reload
    })
    new Daterangepicker(
      this.$refs.daterange,
      {
        startDate: !this.dt1 ? moment().day(-31) : this.dt1,
        endDate: !this.dt2 ? moment() : this.dt2,
      },
      function (d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        self.data_type = self.data_type;
        self.filter.datatype = self.data_type;
        // self.filter.ticket = self.ticket_type;
        self.filter.dt1 = self.dateStart;
        self.filter.dt2 = self.dateEnd;
        self.onDate();
        var json = { dt1: self.filter.dt1, dt2: self.filter.dt2, ticket: self.filter.ticket };
        self.loadAllStatus(json);
      }
    );
    var isAdmin = this.userInfo.role_id < 6;
    var isShipper = this.userInfo.role_id > 10;
    var isKurir = this.userInfo.role_id == 9;
    this.table = createTable(e.tblticket, {
      title: "List Ticket",
      roles: this.$route.params.roles,
      ajax: "/ticket/history",
      frame: false,
      scrollX: true,
      autoWidth:false,
      deferRender : true,
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-spin" style="font-size:50px;color:black;"></i>'},
      selectedRowClass: "",
      toolbarEl: e.toolbar,
      lengthChange: true,
      filter: true,
      filterBy: [0, 1, 2],
      stateSave: true,
      paramData: function (d) {
        var filter = JSON.parse(JSON.stringify(self.filter));
        for (const key in filter) {
            d[key] = filter[key] === false ? 0: filter[key];
        }
      },
    //   "order": [[ 7, 'asc' ]],
      columns: [
        {
          data: "no_resi",
          render: function (data, type, row, meta) {
            return `<span class="link link-order text-info" style="cursor:pointer;" data-id="${row.order_no}">${row.no_resi}</span>`;
          },
        },
        { data: "title" },
        { data: "sender_name" },
        {
          data: "usercs",
          visible: isAdmin ? true : false,
        },
        {
          data: "priorityName",
          render: function (data, type, row, meta) {
            var cls = 'secondary';
            if(data=='High')cls='danger';
            else if(data=='Urgent')cls='warning';
            return (
              "<span class='badge badge-"+cls+"'>" + row.priorityName + "</span>"
            );
          },
        },
        {
          data: "status",
          render: function (data, type, row, meta) {
            var res = "";
            if (row.status == 1){
              res = '<span class="badge badge-info">Open</span>';
            } else if (row.status == 2){
              res = '<span class="badge badge-success">Closed</span>';
            } else if (row.status == 3){
              res = '<span class="badge badge-warning">Pending</span>';
            }

            if (parseInt(row.tracking_status) == 5){
              if (row.problem_description != null){
                res += ' <span class="badge badge-danger">Paket Bermasalah - '+row.problem_description+'</span>';
              } else {
                res += ' <span class="badge badge-danger">Paket Bermasalah</span>';
              }
            } 

            return res;
          },
        },
        {
          data: "pickupStatus",
          visible: isAdmin ? true : false,
          render: function (data, type, row, meta) {
            var badge = '';
            badge = self.infoStatus(row);
            // if (row.cancel == 1)
            //  badge = '<span class="badge badge-danger">Cancel</span>';
            // else if (row.pickupStatus == 0)
            //  badge = '<span class="badge badge-inverse">DRAFT</span>';
            // else {
            //  if (row.trackingStatus == 0)
            //    badge = '<span class="badge badge-secondary">Menunggu Pickup</span>';
            //  else if (row.trackingStatus == 1)
            //    badge = '<span class="badge badge-warning">Proses</span>';
            //  else if (row.trackingStatus == 2)
            //    badge = '<span class="badge badge-success">Terkirim</span>';
            //  else if (row.trackingStatus == 3)
            //    badge = '<span class="badge badge-warning">Proses Retur</span>';
            //  else if (row.trackingStatus == 4) {
            //    if (row.retStatus == 1) 
            //      badge = '<span class="badge badge-success">Retur Diterima</span>';
            //    else if (!row.retStatus && !row.retClaim)
            //      badge = '<span class="badge badge-info">Retur Diserahkan</span>';
            //    else if (row.retClaim == 1)
            //      badge = '<span class="badge badge-danger">Paket Diclaim</span>';
            //    else if (row.retClaim == 2)
            //      badge = '<span class="badge badge-success">Retur Claim disetujui</span>';
            //    else if (row.retClaim == 3)
            //      badge = '<span class="badge badge-danger">Retur Claim ditolak</span>';
            //  } else if (row.trackingStatus == 5) {
            //    badge = '<span class="badge badge-danger">Bermasalah</span>';
            //    if (row.retClaim == 1)
            //      badge = badge + ' <span class="badge badge-danger">Paket Diclaim</span>';
            //    else if (row.retClaim == 2)
            //      badge = badge + ' <span class="badge badge-success">Retur Claim disetujui</span>';
            //    else if (row.retClaim == 3)
            //      badge = badge + ' <span class="badge badge-danger">Retur Claim ditolak</span>';
            //    if (row.retClaim != 0)
            //      badge = badge + ' <span class="badge badge-info">' + row.retNote + '</span>';
            //  }
            // }
            // if(row.updated == 1)
            //  badge += ' <span class="badge badge-warning"> <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Updated</span>';
            return badge;
          },
        },
        { data: "created_on" },
        { data: "lastUpdate" },
        { data: "aging", render: function (data, type, row, meta) { return data + ' Hari' } },
        { data: "totalComment", class:'text-center', render: function (data, type, row, meta) {
          var clsTxt = '';
          var clsIcn = 'far fa-comment';
          let tooltipTxt = row.replied ? 'Sudah dibaca, Belum terjawab' : 'Sudah dibaca, Sudah terjawab';

          if(row.totalComment>1){
              clsIcn = 'far fa-comments';
              if (row.replied) {
                  tooltipTxt = 'Sudah dibaca, Belum terjawab';
              }
          }
          if(row.newUpdate && row.me_last!=1){
            tooltipTxt = row.status == 2 ? 'Belum dibaca, Tidak terjawab' : 'Belum dibaca, Belum terjawab';
            clsTxt='text-primary';
            clsIcn = 'fa fa-comment';
            if(row.totalComment>1)clsIcn = 'fa fa-comments';
          }
          if(row.newUpdate){
            //
          }
          var replied = !row.replied?'':' <i class="fa fa-question"></i> ';
          return ('<div class="btn-comment" style="cursor:pointer;" data-id="'+row.ref_code+'" data-toggle="tooltip" data-html="true" title="<h6><strong>'+tooltipTxt+'</strong></h6>"><span class="'+clsTxt+'"><i class="'+clsIcn+'"></i></span> '+data+replied+'</div>');
        } },
        {
          data: "ref_code",
          sortable:false,
          // visible: isShipper ? false : true,
          render: function (data, type, row, meta) {
            if (row.status == 1) {
              return (
                `<div class="btn-group btn-sm">
  <button type="button" class="btn border-dark shadow-sm dropdown-toggle btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  Ubah Status
  </button>
  <div class="dropdown-menu dropdown-menu-right">
  <button type="button" class="btn btn-sm btn-warning dropdown-item" data-action="change" data-status="3" data-id="` +
                row.id +
                `">Pending</button>
  <button type="button" class="btn btn-sm btn-info dropdown-item" data-action="change" data-status="2" data-id="` +
                row.id +
                `">Closed</button>
  </div>
</div>`
              );
            } else if (row.status == 2) {
              return (`<button type="button" class="btn btn-sm btn-success shadow-sm dropdown-item" data-action="change" data-status="1" data-id="` +
                row.id +
                `">Re-Open</button>`);
            } else if (row.status == 3) {
              return (
                `<div class="btn-group btn-sm">
  <button type="button" class="btn border-dark shadow-sm dropdown-toggle btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  Ubah Status
  </button>
  <div class="dropdown-menu dropdown-menu-right">
  <button type="button" class="btn btn-sm btn-success dropdown-item" data-action="change" data-status="1" data-id="` +
                row.id +
                `">Open</button>
  <button type="button" class="btn btn-sm btn-info dropdown-item" data-action="change" data-status="2" data-id="` +
                row.id +
                `">Closed</button>
  </div>
</div>`
              );
            } else {
              return "-";
            }
          },
        },
      ],
      rowCallback: function (row, data) {},
      drawCallback: function () {
          $(document).ready(function(){
            $('[data-toggle="tooltip"]').tooltip();   
        });
      },
      initComplete: function () {
        $('.loading-overlay').removeClass('show');
      }
    });
  },
};
</script>
<style>
.table-ticket .dataTables_scrollBody{min-height: 100px}
</style>